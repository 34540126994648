import React, { useState } from 'react'
import { Section, Form, Container, Heading, Card, Icon, Columns, Element, Button, Hero, Tag, Level, Modal, Box, Tabs } from 'react-bulma-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { openPopupWidget } from 'react-calendly'
import { Link } from 'gatsby'
import { getDateFormatted } from '../utils/date'

const SolarImpulseCard = () => {

    return (
        <Columns breakpoint='mobile' centered>
            <Columns.Column narrow>
                <Element
                    renderAs={Card}
                >
                    <Card.Content>
                        <Element renderAs='p' mb={4} textTransform='uppercase' textColor='#014478'>
                            Awarded by
                        </Element>
                        <StaticImage
                            src='../../static/logos/Stop_Sound_Solar_Impulse.jpg'
                            alt='Stop-Sound - Soundproofing sustainable solution awarded by Solar Impulse'
                            width={120} height={120} transformOptions={{ cropFocus: 'center' }} quality={100}
                        />
                    </Card.Content>
                </Element>
            </Columns.Column>
        </Columns>
    )
}

const EosSection = ({ children, ...rest }) => {

    return (
        <Element renderAs={Section} {...rest}>
            <Container>{children}</Container>
        </Element>
    )
}
const EosHeading = ({ children, size, textColor, textAlign, textWeight, decoration = true, centered, ...rest }) => {

    return (
        <>
            <Heading
                textSize={size ? size : 2}
                textColor={textColor ? textColor : undefined}
                textWeight={textWeight ? textWeight : 'light'}
                textAlign={centered ? 'center' : textAlign}
                mobile={{ textSize: size ? (size + 1) : 3 }}
                {...rest}
            >
                {children}
            </Heading>
            {
                decoration
                    ?
                    <Heading
                        renderAs='span'
                        subtitle
                        display='block'
                        className={[
                            'decoration',
                            (decoration !== true) ? `decoration-${decoration}` : '',
                            centered ? 'decoration-centered' : ''
                        ].join(' ')}
                    >
                        &nbsp;
                    </Heading>
                    :
                    undefined
            }
        </>
    )
}

const EosHeadingSubtitle = ({ children, ...rest }) => {

    return (
        <Element renderAs='p' textSize={5} mb={6} {...rest}>
            {children}
        </Element>
    )
}

const EosCards = ({ children, columnsProps, ...rest }) => {

    const childrensArray = !children.length ? [children] : children

    return (

        <Columns {...columnsProps}>
            {
                childrensArray.map(
                    (child, index) => (
                        <Columns.Column key={index} {...rest}>
                            {child}
                        </Columns.Column>
                    )
                )
            }
        </Columns>

    )
}

const EosCard = ({ icon, title, image, children, backgroundImage, contentProps, style, tag, ...rest }) => {

    const cardContentStyles = { flexGrow: 1, display: 'flex', flexDirection: 'column' }
    const backgroundImageStyles =
        backgroundImage
            ?
            {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }
            :
            undefined

    return (
        <Card
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                ...backgroundImageStyles,
                ...style
            }}
            {...rest}
        >
            {
                image
                    ?
                    <div className="card-image">
                        <figure className="image">
                            {image}
                        </figure>
                        {
                            tag
                                ?
                                <Element style={{ width: '100%', height: '100%' }} overlay>
                                    <Tag style={{ position: 'absolute', right: '0.5rem', bottom: '0.5rem', backgroundColor: tag.color, borderRadius: 0 }}>
                                        <Element textColor='white' textWeight='bold' textTransform='uppercase' textFamily="secondary">
                                            {tag.text}
                                        </Element>
                                    </Tag>
                                </Element>
                                : undefined
                        }
                    </div>
                    :
                    undefined
            }
            <Card.Content
                {...contentProps}
                style={
                    (contentProps && contentProps.style) ? { ...cardContentStyles, ...contentProps.style } : cardContentStyles
                }
            >
                {
                    icon
                        ?
                        <Icon size='large' mb={5}>{icon}</Icon>
                        :
                        undefined
                }
                {
                    title
                        ?
                        <EosHeading
                            size={5}
                            renderAs='h3'
                            textTransform='uppercase'
                            decoration={false}
                            textColor='primary'
                            mb={3}
                            textWeight='normal'
                            mobile={{ textSize: 5 }}
                        >
                            {title}
                        </EosHeading>
                        :
                        undefined
                }
                {children}
            </Card.Content>
        </Card >
    )
}

const EosList = ({ items, checkmark, ...rest }) => {

    return (
        <Element
            renderAs='ul'
            className={`eos-list ${checkmark !== true ? `checkmark-${checkmark}` : ''}`}
            {...rest}
        >
            {
                items.map(
                    (item, index) => {
                        return (
                            <li key={index}>{item}</li>
                        )
                    }
                )
            }
        </Element>
    )
}

const eosCtaButtonAction = () => {
    console.log("yo")
    openPopupWidget({
        url: 'https://calendly.com/eos-technology/introduction-meeting',
    })
}

const EosCtaButton = ({ children, ...rest }) => {

    return (
        <EosButton
            onClick={eosCtaButtonAction}
            textColor='black'
            {...rest}
        >
            {children}
        </EosButton>

    )
}

const EosButton = ({ children, color, textWeight, ...rest }) => {

    return (
        <Button
            color={color ? color : 'cta'}
            textWeight={textWeight ? textWeight : 'bold'}
            {...rest}
        >
            {children}
        </Button>
    )
}

const EosVideo = ({ embed, children }) => {

    const [playVideo, setPlayVideo] = useState(false)

    return (
        <Element display='relative'>
            {
                (playVideo && embed) ?
                    embed
                    :
                    <>
                        {
                            children
                        }
                        <Element display='flex' justifyContent='center' alignContent='center' alignItems='center' overlay>
                            <Form.Control>
                                <Button color='ghost' size='large' onClick={() => setPlayVideo(true)}>
                                    <Icon align='left' textSize={1} textColor='white'>
                                        <FontAwesomeIcon icon={faPlayCircle} />
                                    </Icon>
                                </Button>
                            </Form.Control>
                        </Element>
                    </>
            }

        </Element>
    )
}

const EosHero = ({ title, subtitle, backgroundImageUrl, ...rest }) => {
    return (
        <>
            <Hero
                size='large'
                style={{
                    backgroundImage: `linear-gradient(135deg, rgba(9, 144, 160, 0.9) 15%, rgba(117, 217, 9, 0) 75%),url(${backgroundImageUrl})`,
                    backgroundSize: 'cover'
                }}
                display='relative'
                {...rest}
            >
                <Element renderAs={Hero.Body}>
                    <Container>
                        <Heading renderAs='p' size={1} mobile={{ textSize: 2, textAlign: 'center' }} textTransform='uppercase' textColor='white' textWeight='bold'>
                            <span dangerouslySetInnerHTML={{ __html: title }} />
                        </Heading>
                        <Heading subtitle mobile={{ textSize: 5, textAlign: 'center' }} textColor='white' textSize={4}>
                            <span dangerouslySetInnerHTML={{ __html: subtitle }} />
                        </Heading>
                        <Element tablet={{ display: 'hidden' }} mt={5}>
                            <SolarImpulseCard />
                        </Element>
                    </Container>
                </Element>
            </Hero>
            <Container>
                <Element
                    display='absolute'
                    style={{ bottom: '-3rem', right: 0 }}
                    mobile={{ display: 'hidden' }}
                >
                    <SolarImpulseCard />
                </Element>
            </Container>
        </>
    )
}

const EosSlider = ({
    currentIndex,
    setCurrentIndex,
    data,
    children
}) => {

    const previousIndex = currentIndex - 1 < 0 ? data.length - 1 : currentIndex - 1
    const nextIndex = currentIndex + 1 === data.length ? 0 : currentIndex + 1

    return (
        <>
            <Box backgroundColor='white' style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }} mb='0' display='relative'>
                {children}
            </Box>
            <Box backgroundColor='white-ter' style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                <Level>
                    <Level.Side>
                        <Level.Item>
                            <Button
                                onClick={() => {
                                    setCurrentIndex(previousIndex)
                                }}
                            >
                                <Icon><FontAwesomeIcon icon={faCaretLeft} /></Icon>
                                <Element renderAs='span'>{data[previousIndex].title}</Element>
                            </Button>
                        </Level.Item>
                    </Level.Side>
                    <Level.Side align="right">
                        <Level.Item>
                            <Button
                                onClick={() => {
                                    setCurrentIndex(nextIndex)
                                }}
                            >
                                <Element renderAs='span'>{data[nextIndex].title}</Element>
                                <Icon><FontAwesomeIcon icon={faCaretRight} /></Icon>
                            </Button>
                        </Level.Item>
                    </Level.Side>
                </Level>
            </Box>
        </>
    )
}

const EosModal = ({
    isDisplayed,
    setIsDisplayed,
    children
}) => {


    return (
        <Modal
            show={isDisplayed}
            onClose={() => setIsDisplayed(false)}
            showClose={false}
            closeOnBlur
            closeOnEsc
        >
            <Element
                renderAs='button'
                className="modal-close is-large"
                aria-label="close"
                style={{ position: 'absolute', right: 20, top: 20 }}
                onClick={() => setIsDisplayed(false)}
                tablet={{ display: 'hidden' }}
            />
            <Modal.Content style={{ width: "auto", maxWidth: 1280 }} px={3}>
                {children}
                <Element
                    renderAs='button'
                    className="modal-close is-large"
                    aria-label="close"
                    style={{ position: 'absolute', right: 20, top: 10, filter: 'invert()' }}
                    onClick={() => setIsDisplayed(false)}
                    mobile={{ display: 'hidden' }}
                />
            </Modal.Content>
        </Modal>
    )
}

const EosTabs = ({ data }) => {

    const [currentTab, setCurrentTab] = useState(0)

    return (
        <>
            <Tabs type='boxed' mb='0' size='large'>
                {
                    data.map(
                        (item, index) => <Tabs.Tab key={item.label} active={index === currentTab} onClick={() => setCurrentTab(index)}>
                            <EosHeading decoration={false} textColor='primary' textSize={6} textWeight='normal' textTransform='uppercase'>
                                {index + 1}.&nbsp;{item.label}
                            </EosHeading>
                        </Tabs.Tab>
                    )
                }
            </Tabs>
            <Element
                className="tabs-box"
                backgroundColor='white'
                style={{
                    border: '1px solid transparent',
                    borderBottomColor: '#dbdbdb',
                    borderLeftColor: '#dbdbdb',
                    borderRightColor: '#dbdbdb',
                }}
                p={6}>
                <Columns centered gap={8}>
                    <Columns.Column size={8}>
                        <EosHeading decoration={false} size={4}>
                            {data[currentTab].title}
                        </EosHeading>
                        <Element renderAs='p' mb={5}>
                            {data[currentTab].description}
                        </Element>
                        <EosCtaButton>Talk to an expert</EosCtaButton>
                    </Columns.Column>
                    <Columns.Column>
                        <Columns.Column textAlign='center'>
                            <Element
                                display='inline-block'
                            >
                                <StaticImage
                                    src='../../static/images/Soundproofing_building.png'
                                    alt='Soundproofing and vibration damping match your products performances requirements'
                                    width={500}
                                    height={500}
                                />
                            </Element>
                        </Columns.Column>
                    </Columns.Column>
                </Columns>
            </Element>
        </>
    )
}

const EosCardText = ({ data }) => {

    return (
        <Columns>
            <Columns.Column textAlign='center' size={4}>
                <Element
                    display='inline-block'
                >
                    <StaticImage
                        src='../../static/images/Soundproofing_building.png'
                        alt='Soundproofing and vibration damping match your products performances requirements'
                        width={500}
                        height={500}
                    />
                </Element>
            </Columns.Column>
            <Columns.Column>
                <Element mb={5}>
                    <EosHeading mb={3} size={4} textColor='primary' textWeight='normal' decoration="primary">{data.title}</EosHeading>
                    <EosHeadingSubtitle mb={3} textColor='grey' textTransform='uppercase' textWeight='bold' textSize={6}>{getDateFormatted(data.publicationDate)}</EosHeadingSubtitle>
                    {data.excerpt}
                </Element>
                <EosButton renderAs={Link} to={`/single-news`} color='primary'>Read more</EosButton>
            </Columns.Column>
        </Columns>
    )
}

export {
    EosCards,
    EosCard,
    EosHeading,
    EosHeadingSubtitle,
    EosSection,
    EosList,
    EosButton,
    EosCtaButton,
    eosCtaButtonAction,
    EosVideo,
    EosHero,
    EosModal,
    EosTabs,
    EosCardText,
    EosSlider
}