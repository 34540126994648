import React, { useState } from "react"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
//import useLanguageSelector from '../hooks/use-language-selector'
import {  Container, Element, Navbar } from "react-bulma-components"
import { EosCtaButton } from "./eos"

const Header = () => {

  const { t } = useTranslation('components', 'slugs')
  const [displayMenu, setDisplayMenu] = useState(false)

  const { nav_menu: tMenu } = t('header')
  const tSlugs = t('slugs:slugs')
  //const languageSelector = useLanguageSelector()

  const TopBarLink = ({ children }) => {

    return (
      <Element renderAs={Link} to="#" textColor='grey' ml={5} py={1} display='inline-block' textSize='7'>
        {children}
      </Element>
    )
  }
  const NavbarLink = ({ children, ...rest }) => {

    return (
      <Navbar.Item renderAs={Link} textTransform='uppercase' activeClassName='current' {...rest}>
        {children}
      </Navbar.Item>
    )
  }

  const NavbarCTA = ({ children, ...rest }) => {

    return (
      <Navbar.Item {...rest}>
        <EosCtaButton>
          {children}
        </EosCtaButton>
      </Navbar.Item>
    )
  }

  return (
    <Element
      renderAs='header'
      style={{
        boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.18)',
        zIndex: 30,
        position: 'relative'
      }}
      textFamily='secondary'
      px={2}
    >
      {/* <Element style={{ borderBottom: '1px solid #f1f1f1' }} mx={3}>
        <Container display='flex' justifyContent='flex-end'>
          <TopBarLink to="#">
            About us
          </TopBarLink>
          <TopBarLink to="#">
            News
          </TopBarLink>
          <TopBarLink to="#">
            Press
          </TopBarLink>
          <TopBarLink to="#">
            Partners
          </TopBarLink>
          <TopBarLink to="#">
            Contact
          </TopBarLink>
        </Container>
      </Element> */}
      <Navbar active={displayMenu} fixed="top">
        <Container>
          <Navbar.Brand>
            <Navbar.Item renderAs={Link} to='/'>
              <img
                src='/identity/Eos_Technology_soundproofing.png'
                alt='Soundproofing and vibration damping solutions for the building & mobility sectors and all industries'
              />
            </Navbar.Item>
            <Navbar.Burger onClick={() => setDisplayMenu(!displayMenu)} />
            <NavbarCTA style={{ marginLeft: 'auto' }} desktop={{ display: 'hidden' }}>
              Meet the team
            </NavbarCTA>
          </Navbar.Brand>
          <Navbar.Menu>
            <Navbar.Container align='right'>
              <NavbarLink to={tSlugs['/']}>
                Home
              </NavbarLink>
              {/* <NavbarLink to={tSlugs['/our-solutions']}>
                Our solutions
              </NavbarLink>
              <NavbarLink to={tSlugs['/applications']}>
                Applications
              </NavbarLink>
              <NavbarLink to={tSlugs['/news']}>
                News
              </NavbarLink> */}
              <NavbarLink to={tSlugs['/contact']}>
                Contact
              </NavbarLink>
              {/* 
              <NavbarLink>
                Realisations
              </NavbarLink>
              */}
              {/* <Element
              desktop={{ display: 'hidden' }}
              >
                <NavbarCTA>
                  Request a meeting
                </NavbarCTA>
              </Element> */}
            </Navbar.Container>
          </Navbar.Menu>
          <Element renderAs={Navbar.Menu} touch={{ display: 'hidden' }}>
            <Navbar.Container align='right'>
              <NavbarCTA>
                {tMenu.cta}
              </NavbarCTA>
            </Navbar.Container>
          </Element>


          {/* <Navbar.Item hoverable>
                <Navbar.Link arrowless>
                  &nbsp;{languageSelector.currentLanguage}&nbsp;
                </Navbar.Link>
                <Navbar.Dropdown>
                  {
                    languageSelector.otherLanguages.map(
                      currentOtherLanguage => (
                        <Navbar.Item
                          renderAs={languageSelector.Link}
                          to={currentOtherLanguage.link}
                          language={currentOtherLanguage.language}
                          style={{ paddingRight: "1rem" }}
                          key={currentOtherLanguage.language}
                        >
                          {currentOtherLanguage.language}
                        </Navbar.Item>
                      ))
                  }
                </Navbar.Dropdown> 
              </Navbar.Item> */}
        </Container>
      </Navbar>
    </Element >
  )
}



export default Header
