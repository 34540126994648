/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useTranslation, Link } from "gatsby-plugin-react-i18next"
import { Element } from "react-bulma-components"

import '../styles/styles.scss'
import Header from "./header"
import Footer from './footer'

const Layout = ({ children, ...rest }) => {

  const location = useLocation()
  const { t } = useTranslation(['slugs', 'components'])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const tSlugs = t('slugs')

  const tCookieBanner = t('components:cookie_banner')

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Element {...rest} renderAs='main'>
        {children}
      </Element>
      <Footer />
      {/* <EosButton
        onClick={
          () => openPopupWidget({
            url: 'https://calendly.com/eos-technology/introduction-meeting',
          })
        }
        style={{ 
          position: 'fixed',
          bottom: '10px',
          right: '10px',
          boxShadow: 'rgba(0,0,0,0.25) 0 2px 5px',
          zIndex: 50
        }}
      >
        Meet the team
      </EosButton> */}
      <CookieConsent
        location="bottom"
        buttonText={`✔ ${tCookieBanner.accept_button}`}
        enableDeclineButton
        declineButtonText={`✘  ${tCookieBanner.decline_button}`}
        cookieName="gatsby-gdpr-google-analytics"
        disableButtonStyles={true}
        buttonClasses="button is-small is-secondary has-text-weight-bold mx-3 my-2"
        declineButtonClasses="button is-small is-primary is-dark has-text-weight-bold mx-3 my-2"
        buttonWrapperClasses="is-flex is-flex-direction-column-reverse"
        containerClasses="is-align-items-center is-justify-content-center has-background-primary"
        onAccept={() => { initializeAndTrack(location) }}
      >
        <p className="title is-5 has-text-white">
          {tCookieBanner.title}
        </p>
        <p className="is-size-6">
          {tCookieBanner.content}
          <br />
          <Link
            to={tSlugs["/privacy-policy"]}
            className="has-text-white"
            style={{ textDecoration: 'underline' }}
          >
            {tCookieBanner.link_privacy_policy}
          </Link>
        </p>
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
