import { faLinkedinIn, faVimeo } from '@fortawesome/free-brands-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Columns, Icon, Element, Footer as BulmaFooter, Container } from 'react-bulma-components'
import { EosHeading } from './eos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const Footer = () => {


  const { t } = useTranslation([ 'slugs'])

  const tSlugs = t('slugs:slugs')

    return (
        <BulmaFooter backgroundColor='primary' style={{ boxShadow: 'rgba(0, 0, 0, 0.18) 0px 0px 30px 0px' }}>
            <Container>
                <Columns textColor='white' gap={8}>
                    <Columns.Column mobile={{ size: 12, textAlign: 'center' }} tablet={{ size: 12, textAlign: 'center' }} desktop={{ textAlign: 'left' }}>
                        <Element>
                            <StaticImage
                                src='../../static/identity/Eos_Technology_soundproofing-white.png'
                                alt='Soundproofing and vibration damping solutions for the building & mobility sectors and all industries'
                                width={300}
                            />
                        </Element>
                    </Columns.Column>
                    <Columns.Column size={4} mobile={{ textAlign: 'center' }} tablet={{ size: 12, textAlign: 'center' }} desktop={{ size: 4 }}>
                        <EosHeading size={4} textColor='white' decoration={false} renderAs='h2'>
                            About EOS Technology
                        </EosHeading>
                        <p>
                            EOS Technology is a division of HOME EOS,<br /><br />
                            Our ambition is to make acoustic confort accessible in every industrial application using solutions that respect our ecosystems.
                            <br /><br />
                            We intent to provide technical solutions to every challenge in acoustic insulation for :<br />
                            Buildings : light partitions, ceilings roofs, floors…<br />
                            Transport systems : railways, automotive, airways<br />
                            Industry : machinery equipment ; industrial sites<br />
                        </p>
                    </Columns.Column>
                    <Columns.Column size={2} mobile={{ textAlign: 'center' }} tablet={{ size: 3, offset: 1 }} desktop={{ size: 2 }}>
                        <EosHeading size={4} textColor='white' decoration={false} renderAs='h3'>
                            Menu
                        </EosHeading>
                        <ul>
                            {[
                                    {
                                        label: "Home",
                                        key:'/'
                                    },
                                    /* {
                                        label: "Our solutions",
                                        key:'/our-solutions'
                                    },
                                    {
                                        label: "Applications",
                                        key:'/applications'
                                    },
                                    {
                                        label: "News",
                                        key:'/news'
                                    }, */
                                    {
                                        label: "Contact",
                                        key:'/contact'
                                    },
                                    
                            ].map(
                                link => (
                                    <li>
                                        <Element textColor='white' renderAs={Link} to={tSlugs[link.key]} mb={3} display='inline-block'>
                                            {link.label}
                                        </Element>
                                    </li>
                                )
                            )}
                        </ul>

                    </Columns.Column>
                    {/*
                    <Columns.Column size={2} mobile={{ textAlign: 'center' }} tablet={{ size: 3 }} desktop={{ size: 2 }}>
                        <EosHeading size={4} textColor='white' decoration={false} renderAs='h3'>
                            About
                        </EosHeading>
                        <ul>
                            {[
                                "About us",
                                "News",
                                "Press",
                                "Partners",
                                "Contact",
                            ].map(
                                name => (
                                    <li>
                                        <Element textColor='white' renderAs={Link} to='#' mb={3} display='inline-block'>
                                            {name}
                                        </Element>
                                    </li>
                                )
                            )}
                        </ul>

                    </Columns.Column> */}

                    <Columns.Column size={2} mobile={{ textAlign: 'center' }} tablet={{ size: 4, offset: 0 }} desktop={{ size: 3 }}>
                        <EosHeading size={4} textColor='white' decoration={false} renderAs='h3'>
                            Contact
                        </EosHeading>

                        <Element renderAs='p' mb={3}>
                            Eos Technology by Home Eos<br />
                            Rue de Fontenelle 2<br />
                            B – 6240 Farciennes<br />
                            +32 71 37 63 80<br />
                            info@home-eos.eu
                        </Element>
                        <Icon>
                            <FontAwesomeIcon icon={faLinkedinIn} />
                        </Icon>
                        <Icon>
                            <FontAwesomeIcon icon={faVimeo} />
                        </Icon>
                    </Columns.Column>
                </Columns>
                <Element textSize={7} textColor='white' renderAs='p' textAlign='center'>
                    © {new Date().getFullYear()}, built by
                    {` `}
                    <Element renderAs='a' textColor='white' href="https://codefathers.be"><u>codefathers</u></Element>
                    {`, design by `}
                    <Element renderAs='a' textColor='white' href="https://gdocreative.be"><u>GDOCreative</u></Element>
                </Element>
            </Container>
        </BulmaFooter>
    )
}
export default Footer